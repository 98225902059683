import { InternationalizationProvider } from "./internationalization";
import languages from "./internationalization/languages";
import { useRoutes } from "react-router-dom";
// import { createCustomTheme } from "./theme";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import routes from "./routes";
import { StoreProvider } from "./store";
import { reducer } from "./reducers";

let initialState = {
  city: null,
  user: null,
  auth: {
    isLoading: true,
  },
  entities: {
    messages: [],
    messageKeys: {},
    deliveries: [],
    pendingDeliveries: [],
  },
  currentTab: 0,
};
const theme = createTheme({});

function App() {
  const content: any = useRoutes(routes());

  return (
    <StyledEngineProvider injectFirst>
      <InternationalizationProvider translationMap={languages}>
        <ThemeProvider theme={theme}>
          <StoreProvider storeReducer={reducer} initialState={initialState}>
            <CssBaseline />
            {content}
          </StoreProvider>
        </ThemeProvider>
      </InternationalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
